.header_item {
  margin-top: 12px;
}
.header_h {
  font-size: 22px;
}
.header_p {
  font-size: 16px;
}
/* banner */

.banner {
  height: 656px;
}
.banner_context {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 0;
  text-align: left;
}
.banner_h {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 16px;
  margin-left: 25px;
  font-weight: normal;
}
.banner_p {
  margin-bottom: 40px;
  font-size: 20px;
  margin-left: 25px;
  line-height: 28px;
}
.banner_button {
  width: 128px;
  height: 42px;
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
  margin-left: 20px;
}
/* footer */

.footer {
  padding-left: 15px;
  background-color: #cfcee3;
  // height: auto; /* Footer height */
  // overflow: hidden;
}
.footerText {
  color: #fffcfc;
  line-height: 0;
}

.footerAnchorText {
  color: #fffcfc;
  margin: 5px 15px 0 5px;
  font-weight: 800;
  cursor: pointer;
}

.footerIcon {
  margin-top: -10px;
  font-size: 18px;
  margin-right: 5px;
}

.footerContactContainer {
  display: flex;
}

.footerColConatiner {
  display: flex;
  font-size: 14px;
  text-align: center;
}

.footerIconAnchor {
  display: flex;
}
