.notifications {
  margin: 10px;
  h4 {
    margin-left: 10px;
    margin-top: 5px;
  }

  .notification_select {
    width: 400px;
    margin-left: 20px;
  }

  .notification_input {
    width: 600px;
    margin-left: 20px;
  }

  .textareacontainer {
    .ant-input {
      border-style: none;
    }
    .ant-input:placeholder-shown {
      margin-top: 5px;
      margin-left: 19px;
      padding-bottom: 340px;
      background-color: #f3f2ff;
      border-style: none;
      display: flex;
    }

    .ant-select-selector {
      border-color: #6259ca;
    }
    .ant-input:focus,
    .ant-input-focused {
      margin-top: 5px;
      margin-left: 10px;
      padding-bottom: 340px;
      background-color: #f3f2ff;
    }
    textarea.ant-input {
      min-height: 365px;
      background-color: #f3f2ff;
      margin-left: 10px;
    }
  }

  .form_tab {
    margin: 20px;
  }

  &_title {
    margin-left: 10px;
  }

  &_btn {
    margin-left: 20px;
  }
}
