@import '../../../variables.scss';

.pharmacyProfile {
  &__card {
    border-radius: 10px;
    margin: 60px 20px 0 20px;
    &__header {
      text-align: center;
      color: #585393;
      font-size: large; 
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: calc(2rem + 1.5vmin);
    }
    &__col {
      width: 90%;
    }
  }
}
