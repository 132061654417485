.updateProductControl {
  display: flex;
  align-items: center;
  &__buttons {
    margin: 20px;
  }
  height: 50px;
  .ant-form-item-label > label {
    width: 100px;
    text-align: end;
  }
}
