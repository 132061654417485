.addModulePrice {
  &__button {
    margin: 7px;
  }
  &__card {
    display: block;
    padding: 7px;
    background-color: #f0f0f0;
    border-radius: 5px;
    &__row {
      display: flex;
      margin: 5px;
      &__title {
        margin-left: 20px;
        margin-right: 40px;
      }
    }
    &__form {
      &__space {
        display: flex;
        margin-bottom: 8;
      }
    }
  }
}
