.modal {
  .ant-modal-title {
    height: 40px;
    font-size: large;
    text-align: center;
    align-content: center;
    color: #f7f9f8;
    letter-spacing: 1px;
  }
  .ant-modal-header {
    height: 40px;
    overflow: hidden;
    background-color: #a29ed0;
  }
  .ant-modal-close {
    top: 3px !important;
  }
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .ant-input {
    background-color: #f7f7f9;
    border-radius: 6px;
  }
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 24px 0 0 0;
  }
  .ant-input:focus {
    border-color: #423aa7;
    box-shadow: 0 0 0 2px rgba(90, 84, 177, 0.1);
  }
  .ant-input:hover {
    border-color: #423aa7;
    box-shadow: 0 0 0 2px rgba(66, 58, 170, 0.2);
  }
}

// -----------

.modal-context-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-form {
    width: 50%;
  }
}
.modal-context-info {
  width: 90%;
  color: #194369;
  text-align: center;
}
.modal-context-form {
  width: 50%;
}
.modal-context-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80%;
  border-top: 1px solid rgba(25, 67, 105, 0.1);
  padding-top: 10px;
  margin: 0px 0 10px 0;
}
.modal-button {
  height: 40px !important;
  color: #fff !important;
  background-color: #423aa7 !important;
  border-radius: 6px !important;
  letter-spacing: 1px !important;
  &:hover {
    transition: 0.5s !important;
    border-color: #413b89 !important;
    box-shadow: 0 0 0 2px rgba(66, 58, 170, 0.2) !important;
    color: #fff !important;
    background-color: #413b89 !important;
  }
  &:focus {
    transition: 0.5s !important;
    color: #fff !important;
    border-color: #413b89 !important;
    box-shadow: 0 0 0 2px rgba(66, 58, 170, 0.2) !important;
    background-color: #413b89 !important;
  }
}

// -----------

.close-icon-container {
  height: 1.5em;
  width: 1.5em;
  background-color: #f7484f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
