@import '../../../variables.scss';
.sidebar__ {
  &.ant-layout-sider {
    background-color: #25233c !important;
  }
  // classes
  .sidebar__brand {
    height: 60px;
    border-bottom: 0.5px solid #808081;
    text-align: center;
    img {
      margin-top: 5px;
    }
  }
  .sidebar__menu {
    background-color: unset;
    margin-top: 2%;
    .sidebar__menu-icon {
      width: 2.7rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-radius: 25%;
      color: #fff;
    }
    span {
      font-size: calc(10px + 0.4vw);
      letter-spacing: 0.2px;
    }
  }
  .__user_information {
    text-align: center;
    margin: 7px 0 10px 0;
    h3,
    h4 {
      font-size: 20px;
      color: #fff;
      font-weight: 400;
    }
  }

  // overrides
  .ant-menu-inline-collapsed {
    .ant-menu-item-selected {
      margin-left: none !important;
      &::after {
        content: none !important;
      }
      &::before {
        content: none !important;
      }
    }
  }

  .ant-menu {
    border-right: none !important;
  }

  .ant-menu-item {
    color: #a29ece !important;
    transition: none !important;
    &::after {
      border-right: none !important;
    }
  }

  .ant-menu-sub {
    background: unset !important;
    color: #a29ece !important;
    transition: unset !important;
  }

  .ant-menu-item:hover {
    background-color: transparent !important;
  }

  .ant-menu-item:active {
    background-color: transparent !important;
  }

  .ant-menu-title-content {
    margin-top: 2px;
    margin-left: 20px;
    a {
      color: #eaedf7 !important;
    }
  }

  .ant-menu-submenu-title:active {
    background-color: transparent !important;
    color: #5a53b8 !important;
    svg {
      color: #5a53b8 !important;
    }
  }

  .ant-menu-sub span {
    font-size: calc(9px + 0.4vw) !important;
  }

  .ant-menu-item-selected {
    padding-left: 10px !important;
    margin-left: 15px;
    overflow: visible;
    border-radius: 57px 0 0 57px;
    & a {
      color: #5a53b8 !important;
    }
    & svg {
      color: #5a53b8 !important;
    }
  }

  .ant-menu-sub > .ant-menu-item {
    margin-inline-start: 22% !important;
    padding-left: 0px !important;
    width: 80%;
  }

  //  menu item edge shapes
  .ant-menu-item-selected::after {
    content: '';
    position: absolute !important;
    background-color: transparent;
    left: 87%;
    inset-block: -50px !important;
    height: 50px;
    width: 11%;
    border-bottom-right-radius: 50px;
    box-shadow: 0 25px 0 0 #eaedf7;
    transition: unset !important;
  }
  .ant-menu-item-selected::before {
    content: '';
    position: absolute;
    background-color: transparent;
    left: 87%;
    bottom: -50px;
    height: 50px;
    width: 11%;
    border-top-right-radius: 50px;
    box-shadow: 0 -25px 0 0 #eaedf7;
  }

  // sub menu item edge shapes
  .ant-menu-sub {
    .ant-menu-item-selected::after {
      content: '';
      position: absolute !important;
      background-color: transparent;
      left: 88%;
      inset-block: -50px !important;
      height: 50px;
      width: 10%;
      border-bottom-right-radius: 50px;
      box-shadow: 0 25px 0 0 #eaedf7;
      transition: unset !important;
    }
    .ant-menu-item-selected::before {
      content: '';
      position: absolute;
      background-color: transparent;
      left: 88%;
      height: 50px;
      width: 10%;
      border-top-right-radius: 50px;
      box-shadow: 0 -25px 0 0 #eaedf7;
    }
  }

  .ant-menu-submenu-title {
    color: #eaedf7 !important;
    height: 44px !important;
    margin-block: 4px !important;
  }

  // for collapsed sidebar icons position
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-inline: calc(50% - 18px) !important;
    padding-top: 0.1rem;
  }
  .ant-menu-inline-collapsed > .ant-menu-item {
    padding-inline: calc(50% - 18px) !important;
    padding-top: 0.1rem;
  }

  .ant-menu-light .ant-menu-item-selected {
    color: #5a53b8 !important;
    background-color: #eaedf7 !important;
  }
}
