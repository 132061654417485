.page-blur-spinner{
position: absolute;
top: 0;
left: 0;
border-radius: 8px;
width: 100%;
height: 100%;
background-color: #4444444c;
z-index: 999;
text-align: center;
padding: 50px;
padding-top: 20%;
}