@import '../../../../variables.scss';

.admin-module-list {
  .Form {
    padding: 10px;

    &__alert {
      width: 250px;
    }
    &__input {
      width: 150;
    }
    &__space {
      margin-bottom: 8;
    }
    &__input {
      width: 200px;
      &__price {
        width: 100px;
      }
      &__range {
        width: 250px;
      }
    }
    &__align {
      display: flex;
    }
    &__minus__icon {
      font-size: 22px;
      margin-top: 5px;
      // margin-top: 45px;
      margin-left: 15px;
      color: $dark-purple;
      &:hover {
        color: #ff4d4d;
        transition: 0.3s;
      }
    }
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    position: absolute;
  }
  .ant-collapse {
    background-color: #fff;
  }

  .collapse_infos {
    display: flex;

    &_item {
      margin: 0 12px 0 10px;
    }
  }

  .ant-switch {
    background-color: #ffa5a8;

    &-checked {
      background-color: #c5edbf !important;
    }

    .ant-switch-inner {
      color: #444;
    }
  }
  &-text {
    margin: 0 35px 0 10px;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin-top: 5px;
  }
}
