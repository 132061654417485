.updateModuleControlPrice {
  display: flex;
  &__price {
    margin: 3px;
    display: flex;
  }
  &_date_label_align {
    padding: 6px 6px 0 0;
  }
}
