.intro-container {
  position: absolute;
  top: 40%;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.its-stake-holders-tab__ {
  display: flex;
  padding: 5px;
  border: #f7f7f9 solid 1px;
  border-radius: 7px;
  .labels-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 167px;
    line-height: 32px;
    background-color: #f7f7f9;
    padding-right: 20px;
    border-radius: 5px;
    label {
      margin-bottom: 18px;
      font-size: large;
    }
    label:last-child {
      margin-bottom: 0;
    }
  }
  .form-container {
    margin: 0 40px 0 20px;
    display: flex;
    width: 334px;
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .ant-form-item {
      margin-bottom: 18px;
    }
    .ant-form-item:last-child {
      margin-bottom: 0px;
    }
  }
  .buttons-container {
    button {
      margin-right: 10px;
    }
  }
}
