.ITSForm {
  &__card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 300px;
    z-index: 12;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .close-btn {
      color: #6c64cd;
      font-size: 22px;
      margin: 0 5px 0 0;
      &:hover {
        color: red;
        transition: 0.3s;
      }
      cursor: pointer;
    }
  }
  &__tables {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 300px;
  }
  &__header {
    font-size: 16px;
    margin: 25px;
  }
  &__login__label {
    font-size: 12px;
    font-weight: bold;
  }
  &__login_card {
    background-color: '#F7F6FF';
    align-content: 'center';
    align-items: 'center';
    width: 450px;
    padding-left: 3vh;
    padding-right: 3vh;

    padding-top: 20px;
    border-radius: 8px;
    background-color: #f7f6ff;
  }
  &__desc {
    margin: 0 0 50px 10px;
  }
  &__checkbox {
    margin-left: 8px;
    .ant-checkbox + span {
      color: #193b54;
      font-size: 11px;
    }
  }
}
