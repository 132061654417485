// Overrides
.application-detail-modal {
  .ant-collapse-header {
    padding: 0px !important;
    align-items: center;
  }
  .ant-collapse-header-text {
    margin-inline-end: 0px !important;
    flex: none !important;
  }

  .ant-collapse-expand-icon {
    height: 2.2rem !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
    padding-block: 0px !important;
  }
}
