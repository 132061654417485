.ActivationPage{

    max-width: 424px;
    &__form{

        &__grid{
            margin-top: 60px;
            padding-left: 50px;
        }
        &__btn{
            
        }
    }
}
