.tutorial_tour {
  color: #dfdddd !important;
  background: #6259ca !important;
  min-width: 33rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
  font-size: 18px !important;
  letter-spacing: 0.1px !important;
  border-radius: 10px !important;
  iframe {
    display: none;
  }
  .card > p {
    margin: revert;
  }
  [data-tour-elem='right-arrow'] {
    color: #f8f8f8;
    &:focus {
      box-shadow: none;
    }
  }
  [data-tour-elem='left-arrow'] {
    margin-right: 12px;
    color: #f8f8f8;
    &:focus {
      box-shadow: none;
    }
  }
  .reactour__dot--is-active {
    color: #f8f8f8;
    background: #f8f8f8;
  }
  &__done_button {
    font-size: 18px;
    height: 35px;
    width: auto;
    border: 0;
    border-radius: 10px;
    background-color: #c98b06;
    color: #fff;
    &:hover {
      background-color: #f8f8f8;
      color: rgb(0, 0, 0);
      transition: 0.3s;
    }
  }
}
