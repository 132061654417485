.ITSForm {
  &__card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 300px;
    z-index: 12;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .close-btn {
      color: #6c64cd;
      font-size: 22px;
      margin: 0 5px 0 0;
      &:hover {
        color: red;
        transition: 0.3s;
      }
      cursor: pointer;
    }
  }
  &__tables {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 300px;
  }
  &__header {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
  }

  &__login__label {
    font-size: 12px;
    font-weight: bold;
  }
  &__login_card {
    background-color: '#F7F6FF';
    align-content: 'center';
    align-items: 'center';
    width: 450px;
    padding-left: 3vh;
    padding-right: 3vh;
    border-radius: 8px;
    background-color: #f7f6ff;
  }
  &__desc {
    margin: 0 0 20px 0;
  }
  &__checkbox {
    margin-left: 8px;
    .ant-checkbox + span {
      color: #193b54;
      font-size: 11px;
    }
  }
}

.custom-disabled {
  background-color: #fff !important;
  color: #000 !important;
  border-right-width: 0px !important;
  &:hover {
    border-right-width: 0px !important;
  }
}

#itsUsername .ant-input[disabled]:hover {
  border-color: #a29ece;
  border-right-width: 0px !important;
}

.control_buttons {
  color: #6259ca;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 24px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  &:hover {
    color: #6259ca;
    border-color: #6259ca;
  }
  &:focus {
    color: #6259ca;
    border-color: #6259ca;
  }
}

.full-screen-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
