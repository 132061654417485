@import '../../../variables.scss';

.manageWarehouse {
  margin: 10px;
  &_context {
    background-color: $popup_background_light;
    padding: 0 0 20px 0;
  }
  &__header {
    
    &__title {
      margin: 20px;
      color: $dark_purple;
      font-size: 22px;
      .ant-breadcrumb-separator {
        color: $dark-purple;
      }
     
    }
  }
  .manageWarehouse_buttons {
   display: flex;
   float: right
;
}
  .ant-breadcrumb > span:last-child {
    color: $dark_purple !important;
  }
  &_buttons {
    margin: 18px;
  }
  &_table_action_btns {
    display: flex;
    &_btn {
      margin: 3px;
    }
  }
  
}
