@import '../../../../variables.scss';

.selectCompanyModules {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-left: 28px;
  overflow-y: scroll;
  align-content: flex-start;
  &__company {
    margin: 7px;
  }
  &__card {
    margin: 7px;
    display: flex;
    align-items: center;
    text-align: start;
    width: 670px;
    color: white;
    font-size: 14px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #a29ece;
    border-radius: 15px;
    height: 55px;
    &:hover {
      background-color: $dark-purple;
      transition: 0.3s;
    }
  }
}
