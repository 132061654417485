// @import 'react-notifications-component/dist/theme.css';
@import './variables.scss';

@font-face {
  font-family: 'Avenir LT Pro 55 Roman';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir LT Pro 55 Roman'),
    url('./assets/fonts/avenir_ff/AvenirLTProRoman.woff') format('woff');
}

// html {
//   overflow: hidden;
// }

* {
  font-family: 'Avenir LT Pro 55 Roman';
  font-weight: 500;
}

body {
  margin: 0;
}

.ant-collapse-header {
  font-size: 16px;
  font-weight: 600;
}

// for all number input hide arrows

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// required star hided
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

// for all input
[class^='ant-input'] {
  font-family: 'Avenir LT Pro 55 Roman';
}
// for all typography
[class^='ant-typography'] {
  font-family: 'Avenir LT Pro 55 Roman';
}
