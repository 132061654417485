@import '../../src/variables.scss';

.layout {
  min-height: 100vh;
  &__background {
    min-height: 100vh;
  }
  .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    background-color: #25233c;
    color: #a29ece;
  }
}

/* layout properties for footer */

#page-container {
  background-color: $light-header-background;
  flex-grow: 1;
}

#content-wrap {
  padding-bottom: 2rem; /* Footer height */
}
/* layout properties for footer */
