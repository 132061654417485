.graph__card {
  border-radius: 20px;
  margin: 10px 0 10px 0;
  word-break: break-all;
  .ant-card-body {
    display: flex;
    flex-direction: column;
  }
  &_type_default {
    min-height: 220px;
    height: auto;
    width: 25vw;
    min-width: 350px;
    max-width: 500px;
  }
  &_type_small {
    min-height: 220px;
    max-width: 350px;
    height: auto;
    position: relative;
  }

  &__image {
    position: absolute;
    height: 120px;
    right: 40px;
    opacity: 0.2;
  }
  &__small_image {
    margin: 0 auto;
    height: 75px;
    width: 75px;
  }

  &__title {
    font-weight: 700;
    color: #193b54;
  }
}
