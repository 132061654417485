.kki_active_filter {
  margin-top: 20px;
  &_input {
    width: 200px;

    .ant-select-item-option-content {
      font-size: 10px;
    }
  }
}
@media only screen and (max-width: 1550px) {
  .kki_active_filter_input {
    width: 150px;
  }
}
@media only screen and (min-width: 1550px) {
  .kki_active_filter_input {
    width: 200px;
  }
}
