.fakePharmacyNewRequest {
  display: flex;
  flex-direction: column;
  overflow: overlay;
  &__icon {
    font-size: 74px;
  }
  &__title {
    margin: 0 0 0 10px;
    color: #193b54;
  }
  &__desc {
    margin: 0 0 0 10px;
    &_secondary {
      margin: 10px 0 0 10px;
    }
  }
  &__button {
    color: #a29ece;
    font-size: 18px;

    &:hover {
      color: #6259ca;
    }
  }

  .content-container {
    width: 100%;
    height: 64vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .ant-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .ant-steps {
    width: 80%;
    margin: 20px auto 0px auto;
  }

  &__stepper {
    .MuiStepIcon-root.MuiStepIcon-active {
      color: #6259ca;
    }
    .MuiStepIcon-root {
      color: #a29ece;
    }
    .MuiTypography-body2 {
      font-family: unset;
    }
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: #6259ca;
    }
  }

  &_readtext {
    margin-left: 10px;
  }
}
