@import '../../../variables.scss';

.loginpage {
  &_forget_pwd_btn {
    font-weight: 600;
    cursor: pointer;
    color: $dark-blue;
    margin-top: 10px;
  }
  &_btns {
    display: flex;
  }
  &__img {
    object-fit: cover;
    height: 450px;
    width: auto;
    &__container {
      padding: 0px 0 120px 10px;
      text-align: center;
      align-items: center;
    }
    &__breakpoint {
      @media (min-width: 0px) and (max-width: 920px) {
        display: none;
      }
    }

    &__stars {
      &__icon {
        font-size: 34px;
        margin-bottom: 10px;
      }

      &__text {
        max-width: 120px;
        line-height: 24px;
        margin-top: 2px;
        color: $dark-blue;
      }

      &__container {
        display: flex;
        margin: 0 70px 0 5px;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__button__submit {
    background-color: #6259ca;
    border: 0px;
    margin-right: 25px;
    &:hover {
      background-color: #534baa;
    }
  }
  &__form {
    &__container {
      margin-top: 100px;
    }
    &_col {
      @media (min-width: 0px) and (max-width: 450px) {
        width: 100% !important;
      }
    }
    &__grid {
      margin-top: 100px;
      padding-left: 50px;
    }
    &__gridImg {
      margin-top: 20px;
      padding-left: 50px;
    }
    &__title {
      color: $dark-blue;
      line-height: 40px;
    }
    &__desc {
      color: $dark-blue;
      line-height: 30px;
    }
  }

  .ant-input {
    border-radius: 10px;
  }
  .ant-tabs-tab-btn {
    color: gray;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-ink-bar {
    background: #534baa;
  }
}
.kemi_logo {
  margin: 30px 125px 0 0;
  height: 70px;
  width: auto;
  @media (min-width: 0px) and (max-width: 450px) {
    display: none;
  }
}
