.checkbox_popup_container {
  &_headline {
    background-color: #6259ca;
    margin-bottom: 50px;
    border-radius: 5px 5px 0 0;
  }

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  max-width: 600px;
  z-index: 13;
  border-radius: 5px;
  padding-bottom: 10px;
  text-align: center;
  word-break: normal;

  .close-btn {
    font-size: 22px;
    margin: 0 5px 0 0;
    &:hover {
      color: red;
      transition: 0.3s;
    }
  }
  .popup-bar-empty-space {
    height: 34px;
  }
  .popup__title {
    font-size: 22px;
    color: #114574;
    padding: 0 10px;
  }
  .popup__message {
    font-size: 20px;
    color: #6c64cd;
    padding: 0 5vw;
  }
  .popup__btn {
    border-radius: 5px;
    border: none;
    padding: 3px 10px;
    height: 35px;
    width: auto;
    font-size: 18px;
    color: #fff;
    margin: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    &_cancel {
      background-color: #ff6565;

      &:hover {
        background-color: #ca4f4f;
        transition: 0.5s;
      }
    }
    &_confirm {
      background-color: #6259ca;
      height: 40px;
      width: auto;
      padding: 0 10px;
      font-size: 17px;
      &:hover {
        background-color: #453e89;
        transition: 0.5s;
      }
    }
  }
  .popup_checkbox {
    margin: 10px;
    color: #6259ca;
    font-size: 20px;
  }
  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(2);
    margin-right: 5px;
  }
}

.checkbox__container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.4);
  z-index: 12;
}

@media only screen and (max-width: 450px) {
  .checkbox_popup_container {
    width: 90% !important;
  }
}

@media only screen and (max-width: 750px) {
  .checkbox_popup_container {
    width: 50%;
  }
}

@media only screen and (min-width: 750px) {
  .checkbox_popup_container {
    width: 40%;
  }
}

// popup types

.popup_default {
  background-color: #fff;
  color: #615a5a;
  .close-btn {
    color: #fff;
    font-size: 19px;
    margin: 5px 10px 4px 0;
    background-color: #ff5454;
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 5px;
    &:hover {
      background-color: #eb4c4c;
      transition: 0.3s;
    }
  }
}
