.companyDashboard {
  margin: 4% 1% 2% 1%;
  &__graphCard {
    width: 550px;
    height: 380px;
    margin: 20px;
    border-radius: 20px;
    z-index: 0;
  }
  .ant-statistic-content {
    text-align: center;
  }
}
.memberNumber {
  color: #6259ca;
  font-size: 40px;
}

.statDividerLine {
  background-color: #6259ca;
  height: 4px;
  width: 60px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  &Container {
    position: relative;
    margin-top: 2px;
    margin-bottom: 60px;
    width: 100%;
  }
}

.userNumberHeader {
  text-align: center;
}
