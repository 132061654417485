.Spinner {
  &_container {
    margin-top: 10%;
    height: 50%;
    min-height: 300px;
  }
  &_spin_icon {
    font-size: 36px;
  }
  &_alert{
    border-radius: 10px;
  }
}
