.AdminDashboard {
  .StatCard {
    display: flex-box;
    margin: 5%;
    border-radius: 10px;
    height: 190px;
    width: 320px !important;
    background-color: #ebeaf7;
    text-align: center;
    overflow: hidden;
    &__pharmacyInfo {
      display: flex;
      margin: 5%;
      padding-top: 2px;
      border-radius: 10px;
      height: 190px;
      width: 290px;
      background-color: #ebeaf7;
      text-align: center;
      &__content {
        font-size: 15px;
        padding-left: 15px;
      }
    }
    &__companyInfo {
      display: flex-box;
      margin: 5%;
      padding-top: 2px;
      border-radius: 10px;
      height: 190px;
      width: 270px;
      background-color: #ebeaf7;
      text-align: center;
      &__content {
        font-size: 15px;
        padding-left: 10px;
      }
    }
    &__altTitles {
      margin-top: 50px;
    }
  }

  .ant-statistic {
    padding-top: 10px;
  }

  .ant-statistic-title {
    color: black;
    font-size: 17px;
    font-weight: bold;
    padding-left: 15px;
  }
  .ant-statistic-content-value {
    font-size: 16px;
  }

  .ant-divider-vertical {
    height: 150px;
    background-color: #fffcfc;
    width: 2px;
    margin-left: 20px;
  }
  .adminGraphCard {
    margin-top: 50px;
    width: 400px;
    height: 380px;
    border-radius: 20px;
  }

  .StatCardTitle {
    margin: 20px 0 0 35px;
    text-align: center;
    font-size: 16px;
  }
}
