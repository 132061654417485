$primary-color: #b4b4b4;
$light-purple: #a29ece;
$dark-purple: #6259ca;
$light-header-background: #eaedf7;
$accept_green: #82ce34;
$dark_green: #70b52c;
$reject_red: #f15e5e;
$dark_red: #c94d4d;
$popup_background_light: #f7f7f9;
$dark_blue: #193b54;
$lightest-purple:#f3f2ff;