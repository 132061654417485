.module-list {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    position: absolute;
  }

  .ant-collapse {
    background-color: #fff;
  }

  .ant-collapse-content-box .module-list {
    padding: 20px;
  }

  .ant-collapse button svg {
    color: #fff !important;
  }

  &__name {
    font-size: 16px;
    margin: 0 30px 0 30px;
  }
  .collapse_infos {
    display: flex;

    &_item {
      margin: 0 12px 0 10px;
    }
  }

  .ant-switch {
    background-color: #ffa5a8;

    &-checked {
      background-color: #c5edbf !important;
    }

    .ant-switch-inner {
      color: #444;
      .ant-switch-inner-checked {
        color: #1b7e12;
      }
      .ant-switch-inner-unchecked {
        color: #990000;
      }
    }
  }
  &-text {
    margin: 0 25px 0 10px;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin-top: 5px;
  }
}
