.addProductControl {
  &__card {
    display: block;
    padding: 7px;
    background-color: #f0f0f0;
    border-radius: 5px;
    &__row {
      display: flex;
      margin: 7px;
      &__button {
        margin-right: 15px;
      }
    }
  }
}
