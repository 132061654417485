.promotion-code-query-button {
  background-color: #a29ece;
  color: white;
  width: 400px;
  height: 40px;
  font-size: 18px;
  position: absolute;
  right: -180px;
  top: 35%;
  transform: rotate(-90deg);
  border-radius: 12px 12px 0 0;
}

.promotion-code-query-box-container {
  position: absolute;
  right: 0px;
  top: 20%;
  .ant-modal-content {
    background-color: #a29ece;
    border-radius: 12px 0 0 12px;
  }
}

.promotion-code-query-box {
  background-color: #a29ece;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promotion-code-query-box__title {
  h3 {
    letter-spacing: 0.1px;
    text-align: center;
    color: white;
  }
  h2 {
    letter-spacing: 0.1px;
    text-align: center;
    color: white;
  }
}

.promotion-code-query-box__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.promotion-code-query-box__input {
  display: flex;
  justify-content: center;
  width: 80%;
  margin-bottom: 15px;
}

.promotion-code-query-box__button {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.promotion-code-query-box__result {
  color: aliceblue;
}
