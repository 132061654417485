@import '../../variables.scss';

// add page parent class to override antd tab

.kemi_pages_tabs {
  margin: 20px;

  .ant-tabs-tabpane {
    padding: 10px 20px 20px 20px;
  }
  .ant-card-body {
    padding: 5px;
    margin: 5px;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-tabs-tab {
    color: #a29ece;
    .active {
      color: black;
    }

    &:hover {
      color: #6259ca;
    }
  }

  .ant-tabs-tab-btn {
    color: #a29ece;
    font-size: 17px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    color: #a29ece;
    .active {
      color: #6259ca;
    }
    &:hover {
      color: #6259ca;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-radius: 12px 12px 0 0;
  }
  .ant-tabs-top,
  .ant-tabs-bottom {
    background-color: white;
    margin: 10px;
    border-radius: 15px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6259ca;
    margin-left: 20px;
    margin-right: 20px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #fff;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    background-color: $light-header-background;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}

.kemi_pages_cards {
  margin: 10px;
}

.kemi_collapse {
  .ant-collapse {
    border: 0;
  }
}

.kemi_pages_margin {
  margin-top: 20px;
}

.kemi_pages_text {
  &_title {
    font-weight: bolder;
    font-size: 18px;
  }
}

// global overwrite of ant d
