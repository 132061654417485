.admin_active_reqs {
  &_statsbar {
    display: flex;
  }
  &__export__button {
    margin: 0.5em 2em 0.5em 0;
  }
  &_empty_table_text {
    text-align: center;
  }
}
