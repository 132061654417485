@import '../../../variables.scss';

.requestDetailCard {
  &_openbtn {
    font-size: 15px;
  }

  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 80%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: #fff;
    z-index: 5;
    border-radius: 15px;
    padding: 5px;
    display: flex;

    &_tabs_sections {
      width: 100%;
      overflow-x: auto;

      &_content {
        padding: 0 15px;
        overflow-y: scroll;
      }

      &_message {
        padding: 15px 0 0 15px;
        font-size: 18px;
      }
    }

    &_table_container {
      overflow-x: auto;
      overflow-y: auto;
      min-width: 1000px;

      table {
        width: 100%;
        text-align: left;
        border-radius: 2px 2px 0 0;
        border-collapse: separate;
        border-spacing: 0;
      }
    }

    //tabs button
    &_tabs_buttons {
      display: inline-grid;
      align-self: center;
      margin-left: -80px;

      &_btn {
        border: 0;
        background-color: #fff;
        border-radius: 10px 0 0 10px;
        height: 220px;
        width: 75px;

        &_text {
          transform: rotate(270deg);

          overflow-wrap: initial !important;
        }

        &_icon {
          color: $dark-purple;
          font-size: 28px;
          margin-bottom: 10px;
        }
      }

      .active {
        z-index: 6;
        box-shadow: rgba(0, 0, 0, 0.15) -6.4px 0.4px 4.2px;
        color: #193b54;
      }

      .passive {
        color: #999;
        background-color: rgb(234, 234, 234);
      }

      .problem_passive {
        background-color: #ffe7e8;
        color: #f2767d;
      }
      .turn-badge {
        margin-right: 5px;
        transform: rotate(90deg);
      }
      .passive_icon {
        color: #999;
      }
    }

    // tab section
    &_tabs_sections {
      .ant-tag {
        font-size: 14px;
        height: auto;
        width: auto;
        text-align: center;
        border-radius: 8px;
      }
    }

    &_close_btn {
      color: #fff;
      font-size: 18px;
      &:hover {
        color: #ff5454;
        transition: 0.3s;
      }
      &_container {
        position: absolute;
        right: 0;
        top: 0;
        &_relative_parent {
          position: relative;
        }
      }
    }

    &_header {
      overflow-y: auto;
      background-color: $dark-purple;
      padding: 8px;
      border-radius: 15px;
      margin-left: 10px;
      .status_text {
        color: #fff;
        font-size: 18px;
        margin-right: 10px;
      }
      &_req_info {
        margin: 0 20px;
        text-align: center;
        &_title {
          font-weight: 600;
        }
        &_content {
        }
      }

      .request_number_text {
        text-align: center;
        font-size: 18px !important;
        color: #fff;
        font-weight: 600;
        margin-top: 18px;
        span {
          font-weight: 800;
        }
      }
    }
  }

  &_container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(64, 64, 64, 0.4);
    z-index: 1;
  }

  .tr-head {
    color: $dark-purple;
    font-weight: 600;
    font-size: 16px;
  }

  .tr-input {
    width: 90%;
    max-width: 410px;
    min-width: 350px;
    border-radius: 5px;
  }

  .th-price-tag {
    width: auto;
    background-color: $light-purple;
    text-align: center;
    border-radius: 15px;
    padding: 6px 14px 6px 14px;
    color: #fff;
    font-weight: 600;
  }
}

@media only screen and (max-width: 750px) {
  .requestDetailCard_popup {
    width: 85%;
    left: 57%;

    &_tabs_buttons {
      margin-left: -60px;
    }
  }
}

@media only screen and (min-width: 750px) {
  .requestDetailCard_popup {
    width: 88%;
    left: 54%;
  }
}
