// reset password email request form

.reset-form {
  margin-top: 10vmin;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.reset-form-back-btn {
  margin-top: 10vmin;
}

.ResetPasswordRequest,
.ResetPasswordForm {
  .EmailForm {
    min-width: 400px;
    padding-top: 10vmin;
  }

  .changePassword__card {
    background-color: #f0f2f5;
    width: auto;
    max-width: 320px;
    &__title {
      text-align: center;
    }
    &__icon {
      font-size: 38px;
    }
  }
  .change_password {
    &_input {
      font-size: 16px;
      background-color: #f1f1f1;
      border: 0;
      border-radius: 5px;
      margin: 10px;
    }
    &_submit {
      height: 50px;
      width: 135px;
      border-radius: 10px;
      color: #fff;
      font-size: 18px;
      border: 0;
      background-color: #6259ca;
      margin: 10px;
    }
    &_form1 {
      margin-top: 20px;
      text-align: center;
      max-width: 330px;
    }
    &_text_description {
      text-align: center;
      color: #847d7d;
    }
    &_text_description_small {
      font-weight: 600;
      text-align: center;
      color: #193b54;
      text-decoration-color: #4fabed;
    }
    &_kemi_logo {
      margin: 30px 125px 0 0;
      height: 70px;
      width: auto;
      @media (min-width: 0px) and (max-width: 450px) {
        display: flex;
      }
    }
  }

  .back_btn {
    margin-left: 10px;
  }
}
