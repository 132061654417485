@import '../../../../variables.scss';
.newRecord {
  &__title {
    background-color: #ffefb7;
    padding: 5px 0 5px 15px;
  }
  .Form {
    &__alert {
      width: 250px;
    }
    &__input {
      width: 150;
    }
    &__space {
      margin-bottom: 8;
    }
    &__input {
      width: 200px;
      &__price {
        width: 100px;
      }
      &__range {
        width: 250px;
      }
    }
    &__align {
      display: flex;
    }
    &__minus__icon {
      display: flex;
      flex-direction: column;
      font-size: 22px;
      margin-top: 5px;
      margin-left: 15px;
      color: $dark-purple;
      &:hover {
        color: #ff4d4d;
        transition: 0.3s;
      }
    }
    .ant-form-item-label {
      margin-left: 15px;
    }
  }
  background-size: cover;

  // TODO delete the steper from fiyat bkz : https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
}
