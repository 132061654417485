@import '../../../variables.scss';

.tablecomponent {
    .tableDecideButtons {
        margin-top: -68px;
    }
    &__postbtn {
        border: 0;
        color: #fff;
        margin: 5px;
        margin-bottom: 5px;
        padding: 0 !important;
        &__accept {
            background-color: $accept_green;
            &:hover {
                background-color: $dark_green;
                color: #fff;
            }
            &:focus {
                background-color: $accept_green;
                color: #fff;
            }
        }
        &__reject {
            background-color: $reject_red;
            &:hover {
                background-color: $dark_red;
                color: #fff;
            }
            &:focus {
                background-color: $reject_red;
                color: #fff;
            }
        }
    }
    &__postbtns__container {
        margin-bottom: 10px;
    }

    // override
    .ant-table-selection-extra {
        & svg {
            margin-top: 4px;
            width: 1.5em;
            height: 1.4em;
        }
    }
    .ant-dropdown-open {
        & svg {
            color: $dark-purple;
        }
    }
}
