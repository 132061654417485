@import '../../../../variables.scss';
.createWholesaler {
  &__space {
    display: flex;
    margin-bottom: 8;
  }
  &__title {
    color: #6259ca;
  }
  &__row {
    margin-left: -20px;
  }
}
.formlist__minus__icon {
  font-size: 22px;
  margin-top: 5px;
  // margin-top: 45px;
  margin-left: 15px;
  color: $dark-purple;
  &:hover {
    color: #ff4d4d;
    transition: 0.3s;
  }
}
