.updateModuleNote {
  display: flex;
  align-items: center;
  &__buttons {
    margin: 20px;
  }
  margin-left: 5px;
  height: 50%;
  margin-top: 12px;
  .ant-form-item-label > label {
    width: 100px;
    text-align: end;
  }
  .moduleNoteInput {
    width: 75%;
  }
  .moduleNoteButton {
    width: 25%;
  }
}
