.pharmacyRequestAddProduct {
  &__space {
    display: flex;
    margin-bottom: 8;
  }
  &_margin {
    margin: 0 0 5px 0;
  }
  // &__ConfirmButton {
  //   margin: 20px 0 0 0;
  // }
  &_add_row_btn {
    border-radius: 5px;
    border: 0;
    background-color: #a29ece;
    color: #fff;
  }
  &_input {
    min-width: 450px;
  }
}
