.confirmation_popup {
  &_headline {
    background-color: #6259ca;
    margin-bottom: 50px;
    border-radius: 5px 5px 0 0;
    padding: 10px;
  }

  &_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: auto;
    max-width: 600px;
    background-color: #fff;
    z-index: 5;
    border-radius: 5px;
    padding-bottom: 10px;
    text-align: center;
    word-break: normal;

    .popup__title {
      font-size: 22px;
      color: #114574;
      padding: 0 10px;
    }

    .popup__message {
      font-size: 20px;
      color: #6c64cd;
      padding: 0 5vw;
    }

    .popup__btn {
      border-radius: 5px;
      border: none;
      padding: 3px 10px;
      height: 35px;
      width: 100px;
      font-size: 18px;
      color: #fff;
      margin: 4px;
      cursor: pointer;
      &_cancel {
        background-color: #ff6565;

        &:hover {
          background-color: #ca4f4f;
          transition: 0.5s;
        }
      }

      &_confirm {
        background-color: #6259ca;

        &:hover {
          background-color: #453e89;
          transition: 0.5s;
        }
      }
    }
  }
}

.confirmation__container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.4);
  z-index: 1;
}

@media only screen and (max-width: 450px) {
  .confirmation_popup_container {
    width: 90% !important;
  }
}

@media only screen and (max-width: 750px) {
  .confirmation_popup_container {
    width: 50%;
  }
}

@media only screen and (min-width: 750px) {
  .confirmation_popup_container {
    width: 40%;
  }
}
