.ContractPage{
    &_markdownCanvas{
        width: 100%;
        padding: 2% 10% ;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        margin-bottom: 2%;
    }
    
  }