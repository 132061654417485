@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Avenir LT Pro 55 Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 55 Roman'),
      url(/src/assets/fonts/avenir_ff/AvenirLTProRoman.woff) format('woff');
  }

  * {
    @apply font-sans; /* Apply the default sans-serif font family */
  }
}
