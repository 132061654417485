.stockloss_active_filter {
  &_input {
    width: 200px;
    .ant-select-item-option-content {
      font-size: 10px;
    }
  }
}
@media only screen and (max-width: 1550px) {
  .stockloss_active_filter_input {
    width: 150px;
  }
}
@media only screen and (min-width: 1550px) {
  .stockloss_active_filter_input {
    width: 200px;
  }
}
.companies_form_select > .ant-select-selector {
  height: 30px !important;
}
