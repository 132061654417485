@import '../../../variables.scss';

.header {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: #6059c3;
  background-color: #fff;
  height: 60px;
  &__exit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    width: 124px;
    cursor: pointer;
    &:hover {
      color: $dark-purple;
      transition: 0.3s;
    }
    &__logo {
      font-size: 32px;
    }
    &__text {
      margin-left: 5px;
      font-size: 22px;
    }
  }

  &_helpmenu {
    margin: 2px 30px 0 0;
    div {
      span {
        padding: 8px 10px;
        border-radius: 5px;
      }
      :nth-child(2) {
        margin: 0px 3px;
      }
    }
  }

  @media only screen and (max-width: 850px) {
    &_helpmenu {
      display: none;
    }
  }
}
