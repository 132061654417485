.selectCompanies {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-top: -15px;
  margin-left: 15px;
  flex-direction: column;
  &__company {
    margin: 15px;
    &__card {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      width: 240px;
      height: 75px;
      color: white;
      font-size: 20px;
      background-color: #a29ece;
      border-radius: 15px;
    }
  }
}
