@import '../../../../src/variables.scss';

.kemi_info_icon {
  color: #a08ffe !important;
}
.kemi_info_btn {
  background-color: #a08ffe;
}

.pharmacyInfoGuide__conatiner {
  .ant-result {
    padding: 0;
  }
}

.content_container_tooltip {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 15px 0;
  margin: 60px 20px 0 20px;
}

.video_help_icon {
  font-size: 24px;
}
