@import '../../../variables.scss';
.TextReader {
  padding: 5px;
  &--dragger {
    margin: 10px;
    max-width: 570px;
  }
  .ant-upload.ant-upload-drag {
    background-color: #f3f2ff !important;
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: $light-purple;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: $dark-purple;
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    margin: 0 10px 10px 10px;
    max-width: 570px;
  }
  .exapmletxt--link {
    margin: 5px 0 5px 0;
  }
}
