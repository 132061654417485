.pharmacy-users-tab__ {
  .form-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    height: 32px;
    > div > :nth-child(1) {
      margin-right: 20px;
    }
    > div > :nth-child(3) {
      margin: 0 20px;
    }
  }
  .activation-button-container {
    display: flex;
  }
  .activation-button {
    background-image: url(../../../../../assets/img/letter_icon.png);
    width: 24px;
    height: 24px;
    align-content: center;
    text-align: center;
    color: aliceblue;
    text-shadow: 1px 1px 3px black;
    font-size: 11px;
    cursor: pointer;
  }
  .edit-button {
    background-image: url(../../../../../assets/img/edit_icon.png);
    width: 22px;
    height: 22px;
    align-content: center;
    text-align: center;
    color: aliceblue;
    text-shadow: 1px 1px 3px black;
    margin-top: 2px;
    cursor: pointer;
  }
}

.ant-switch {
  &-checked {
    background-color: #413b89 !important;
  }

  .ant-switch-inner {
    color: #444;
  }
}
